import styled, { keyframes } from "styled-components"

export const slideLeft = keyframes`
  from {
    opacity: 0;
    transform:  translateX(250px)  ;
  }

  to {
    transform:  translateX(0)  ;
    opacity: 1;
  }
`

export const slideRight = keyframes`
  from {
    opacity: 0;
    transform:  translateX(-250px)  ;
  }

  to {
    transform:  translateX(0)  ;
    opacity: 1;
  }
`

export const slideUp = keyframes`
  from {
    opacity: 0;
    transform:  translateY(250px)  ;
  }

  to {
    transform:  translateY(0)  ;
    opacity: 1;
  }
`

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }

`
