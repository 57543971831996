import React from "react"
import loadable from "@loadable/component"
import tw, { styled } from "twin.macro"
import { P, H3, H2, HeaderDivider } from "./page-elements"
import { VisibleElement } from "./shared/visible-element"
import { slideRight } from "./shared/keyframes"
const Video = loadable(() => import("./shared/video"))
const Section = loadable(() => import("./shared/section"))

const Content = {
  title: "How we work",
  text1:
    "Our personnel and staff have years of experience in the Residential & Commercial Painting Industry as well as the Wood Refinishing & Restoration Industry . We employ project managers that will guide you Step-By-Step through your home improvement project or commercial project. They are knowledgeable and friendly and will always be there to answer any concerns or questions that may come up during a job.",
  text2:
    "Our on-site foremen have years of experience in painting, construction, and running a work site efficiently. We also employ qualified and reliable painters and workmen who are professional and highly skilled.",
  text3:
    "Glacier Painting will provide an unparalleled understanding, in-depth product knowledge, expertise, and thoughtful customer service to give you the best experience with your Madison Home Improvement Project, Commercial Painting Project, & or Wood Refinishing Project.",
}

const HowWeWorkSection = () => {
  return (
    <Section background="base-color" size="lg">
      <Row>
        <VisibleElement>
          {({ visible }: { visible: boolean }) => (
            <Column
              className={`my-8 md:my-0 md:mr-10 animated ${
                visible ? "visible" : ""
              }`}
            >
              <H2 color="darker">{Content.title}</H2>
              <HeaderDivider />
              {/* <Title>{Content.title}</Title> */}
              <Text color="dark">{Content.text1}</Text>
              <Text color="dark">{Content.text2}</Text>
              {/* <Text color="dark">{Content.text3}</Text> */}
            </Column>
          )}
        </VisibleElement>
        <Column>
          <Video
            videoSrcURL="https://www.youtube.com/embed/BxKJpZrXY3E"
            videoTitle="Official Music Video on YouTube"
          />
        </Column>
      </Row>
    </Section>
  )
}

const Row = tw.div`
  flex
  flex-col
  justify-between
  md:flex-row
  md:mx-auto
  px-page-gutter
  md:px-0
  w-full
  max-w-screen-xl
`

const Column = styled.div`
  ${tw`
    md:px-0
    w-full
    md:w-1/2
    flex
    flex-col
  `};

  &.animated {
    opacity: 0;
    &.visible {
      animation-name: ${slideRight};
      animation-fill-mode: forwards;
      animation-duration: 0.8s;
      animation-timing-function: ease-in-out;
    }
  }
`

const Title = styled(H3)`
  ${tw`
    text-header
    text-3xl
    font-semibold
  `}
`

const Text = styled(P)`
  ${tw`
    mt-4
`}
`

export default HowWeWorkSection
