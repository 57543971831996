import React from "react"
import VisibilitySensor from "react-visibility-sensor"

export const VisibleElement: React.FC = ({ children, offset }) => {
  const [hasBeenVisible, setHasBeenVisible] = React.useState(false)
  const handleChange = (isVisible: boolean) => {
    if (isVisible) {
      setHasBeenVisible(true)
    }
  }

  return (
    <VisibilitySensor
      offset={{ top: 100 }}
      partialVisibility={true}
      active={!hasBeenVisible}
      onChange={handleChange}
    >
      {children({ visible: hasBeenVisible })}
    </VisibilitySensor>
  )
}
